<template>
  <!-- Modal dialog: Logout -->
  <v-dialog 
    transition="dialog-top-transition"
    v-model="dialog" 
    width="500"
    @keydown.esc="no" 
    persistent>
    <v-card-title class="dialogTitleClass bg-dialog-error"><v-icon class="mr-2 icon-error">mdi-login-variant</v-icon>{{ title }}</v-card-title>
    <v-card-text
      v-show="!!message"
      class="dialogTextClass"
      v-html="message"
    ></v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col>
            <v-btn 
            v-if="!options.noconfirm"
            class="btn-red" 
            @click.native="yes">
                <span class="s-text-white-bold">SI</span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn 
            v-if="!options.noconfirm"
            class="btn-gray" 
            @click.native="no">
                <span class="s-text-white-bold">NO</span>
            </v-btn>              
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-dialog>
</template>

<script>
  export default {
    name: "DialogLogout",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          noconfirm: false,
        },
      };
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      yes() {
        this.resolve(false);
        this.dialog = false;
        window.localStorage.removeItem('infoUser');
        /* Back to Login */
        this.$router.push({
          path: '/',
        });
      },
      no() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>